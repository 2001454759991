import { useStaticQuery, graphql } from "gatsby";

export const useJobListingQuery = () => {
  const { allSanityJobListing } = useStaticQuery(graphql`
    query {
      allSanityJobListing {
        listings: nodes {
          title
          slug {
            current
          }
          locationRef {
            locationName
          }
          employmentTypeRef {
            employmentTypeName
          }
          departmentRef {
            departmentName
          }
          description: _rawDescription(resolveReferences: { maxDepth: 10 })
          detailsOne: _rawDetailsOne(resolveReferences: { maxDepth: 10 })
          detailsTwo: _rawDetailsTwo(resolveReferences: { maxDepth: 10 })
          link {
            linkText
            target
          }
        }
      }
    }
  `);
  return allSanityJobListing || {};
};
