import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useEmploymentTypeQuery = () => {
  const { allSanityEmploymentType } = useStaticQuery(graphql`
    query {
      allSanityEmploymentType {
        types: nodes {
          employmentTypeName
        }
      }
    }
  `);
  return allSanityEmploymentType || {};
};
