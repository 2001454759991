import React from "react";

import BlueCross from "@svg/bluecross.svg";
import GreyCross from "@svg/greycross.svg";

const MobileFilter = ({
  types,
  locations,
  departments,
  handleClose,
  selectStyles,
  updateLocation,
  updateDepartment,
  updateType,
  state,
  nukeFilters,
}) => {
  return (
    <div className="flex flex-col w-full relative px-gutter pt-20">
      <BlueCross
        className="absolute top-10 right-5 cursor-pointer"
        onClick={handleClose}
      />
      <h1 className="blockB3 text-blue-dark mb-8">Search Filters</h1>
      <h1 className="blockB5 pb-2">Disciplines</h1>
      <select
        className={selectStyles}
        onChange={(e) => {
          e.target.value === "default"
            ? updateDepartment("default")
            : updateDepartment(departments?.[e.target.value]?.departmentName);
        }}
      >
        <option selected={state?.department === "default"} value="default">
          All disciplines
        </option>
        {departments?.map((item, i) => (
          <option
            key={i}
            value={i}
            selected={state?.department === item?.departmentName}
          >
            {item?.departmentName}
          </option>
        ))}
      </select>
      <h1 className="blockB5 pb-2">Employment Type</h1>
      <select
        className={selectStyles}
        onChange={(e) => {
          e.target.value === "default"
            ? updateType("default")
            : updateType(types[e.target.value]?.employmentTypeName);
        }}
      >
        <option selected={state?.type === "default"} value="default">
          All Employment Types
        </option>
        {types?.map((item, i) => (
          <option
            key={i}
            value={i}
            selected={state?.type === item?.employmentTypeName}
          >
            {item?.employmentTypeName}
          </option>
        ))}
      </select>
      <h1 className="blockB5 pb-2">Location</h1>
      <select
        onChange={(e) => {
          e.target.value === "default"
            ? updateLocation("default")
            : updateLocation(locations?.[e.target.value]?.locationName);
        }}
        className={selectStyles}
      >
        <option selected={state?.location === "default"} value="default">
          All Locations
        </option>
        {locations?.map((item, i) => (
          <option
            key={i}
            value={i}
            selected={state?.location === item?.locationName}
          >
            {item?.locationName}
          </option>
        ))}
      </select>
      <div className="flex items-center">
        <GreyCross className="mr-2" />
        <p
          className="blockB5 text-[#828282] cursor-pointer"
          onClick={nukeFilters}
        >
          Clear all filters
        </p>
      </div>
      <button
        className="button-orange w-full mt-10  self-start"
        onClick={handleClose}
      >
        See results
      </button>
    </div>
  );
};

export default MobileFilter;
