import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useDepartmentQuery = () => {
  const { allSanityDepartment } = useStaticQuery(graphql`
    query {
      allSanityDepartment {
        departments: nodes {
          departmentName
        }
      }
    }
  `);
  return allSanityDepartment || {};
};
