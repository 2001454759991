import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useLocationQuery = () => {
  const { allSanityLocation } = useStaticQuery(graphql`
    query {
      allSanityLocation(sort: { fields: locationName, order: ASC }) {
        locations: nodes {
          locationName
        }
      }
    }
  `);
  return allSanityLocation || {};
};
