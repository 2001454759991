import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import { SanityImage } from "@components/sanity/sanityImage";
import { useLocationQuery } from "@querys/useLocationQuery";
import { useDepartmentQuery } from "@querys/useDepartmentQuery";
import { useEmploymentTypeQuery } from "@querys/useEmploymentTypeQuery";
import { useJobListingQuery } from "@querys/usejobListingQuery";
import { useNavQuery } from "@querys/useNavQuery";
import PortalModal from "@components/UI/portalModal";
import { motion, AnimatePresence } from "framer-motion";
import MobileFilter from "@components/forms/mobileFilter";
import { useSiteContext, useFilters } from "@lib/context";

import Tune from "@svg/tune.svg";
import GreyCross from "@svg/greycross.svg";

const Opportunities = ({ data: { page } }) => {
  const { state } = useSiteContext();
  const { updateLocation, updateDepartment, updateType, nukeFilters } =
    useFilters();
  const { seo, heading, intro, cta, images } = page || {};
  const { listings } = useJobListingQuery();
  const { locations } = useLocationQuery();
  const { departments } = useDepartmentQuery();
  const { types } = useEmploymentTypeQuery();
  const { logo } = useNavQuery();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const selectStyles = `blockB5 input-field-other mb-7 w-[100px] h-[50px] custom-select`;

  const locationFilter = (item) => {
    const filtered = item?.locationRef?.filter(
      (loc) => loc?.locationName === state?.location
    );
    return filtered?.[0]?.locationName;
  };

  const departmentFilter = (item) => {
    const filtered = item?.departmentRef?.filter(
      (dep) => dep?.departmentName === state?.department
    );
    return filtered?.[0]?.departmentName;
  };

  const filteredListings = listings?.filter((listing) => {
    if (
      state?.type !== "default" &&
      state?.department !== "default" &&
      state?.location !== "default"
    ) {
      return (
        state?.department === departmentFilter(listing) &&
        state?.type === listing?.employmentTypeRef?.employmentTypeName &&
        state?.location === locationFilter(listing)
      );
    } else if (state?.type !== "default" && state?.department !== "default") {
      return (
        state?.department === departmentFilter(listing) &&
        state?.type === listing?.employmentTypeRef?.employmentTypeName
      );
    } else if (
      state?.department !== "default" &&
      state?.location !== "default"
    ) {
      return (
        state?.department === departmentFilter(listing) &&
        state?.location === locationFilter(listing)
      );
    } else if (state?.type !== "default" && state?.location !== "default") {
      return (
        state?.type === listing?.employmentTypeRef?.employmentTypeName &&
        state?.location === locationFilter(listing)
      );
    } else if (state?.department !== "default") {
      return state?.department === departmentFilter(listing);
    } else if (state?.type !== "default") {
      return state?.type === listing?.employmentTypeRef?.employmentTypeName;
    } else if (state?.location !== "default") {
      return state?.location === locationFilter(listing);
    } else {
      return listing;
    }
  });

  return (
    <Layout hideNav={true}>
      <PageMeta {...seo} />
      {/* banne r */}
      <div className="bg-blue flex flex-col-reverse lg:grid grid-cols-2 lg:gap-x-10 px-gutter pt-[194px] pb-[60px]">
        <a
          href="https://www.cosgroves.com/"
          target="_blank"
          className="absolute top-6 lg:top-14 left-gutter md:left-[50px] xl:left-[100px]"
        >
          <SanityImage className="w-[144px] lg:w-[266px] h-auto" image={logo} />
        </a>
        <div className="self-center pt-14 pl-2">
          <h1 className="text-orange blockH1 pb-6">{heading}</h1>
          <p className="text-white blockB4 pb-6">{intro}</p>
          <p className="text-white blockB4">{cta}</p>
        </div>
        <motion.div
          initial={{ opacity: 0, x: 40 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 1.5 }}
          className="flex"
        >
          <div className=" translate-x-2 lg:translate-x-10 translate-y-1/4 z-10">
            <SanityImage image={images[0]} />
          </div>
          <div className="-translate-x-2 lg:translate-x-0  ">
            <SanityImage image={images[1]} />
          </div>
        </motion.div>
      </div>

      <div className=" lg:grid grid-cols-10  2xl:gap-x-20 pt-20 pb-[60px] lg:pb-[232px] text-blue-dark px-gutter">
        {/* mobile filters */}
        <div className="lg:hidden flex justify-between">
          <h1 className="blockB3 font-poppins-medium ">{`${filteredListings.length} Results`}</h1>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <Tune className="mr-2" />
            <h1 className="text-blue-dark blockB5 font-poppins-medium">
              Filter Results
            </h1>
          </div>
        </div>
        <PortalModal isShowing={open}>
          <AnimatePresence>
            <MobileFilter
              types={types}
              locations={locations}
              departments={departments}
              handleClose={handleClose}
              selectStyles={selectStyles}
              updateLocation={updateLocation}
              updateDepartment={updateDepartment}
              updateType={updateType}
              state={state}
              nukeFilters={nukeFilters}
            />
          </AnimatePresence>
        </PortalModal>
        {/* filters */}
        <div className="hidden lg:block col-start-1 col-end-4  sticky top-0 self-start pt-4">
          <h1 className="blockB3 font-poppins-medium pb-10">{`${filteredListings.length} Results`}</h1>
          <div className="flex flex-col">
            <h1 className="blockB6 pb-2">Disciplines</h1>
            <select
              className={selectStyles}
              onChange={(e) => {
                e.target.value === "default"
                  ? updateDepartment("default")
                  : updateDepartment(
                      departments?.[e.target.value]?.departmentName
                    );
              }}
            >
              <option
                selected={state?.department === "default"}
                value="default"
              >
                All Disciplines
              </option>
              {departments?.map((item, i) => (
                <option
                  key={i}
                  value={i}
                  selected={state?.department === item?.departmentName}
                >
                  {item?.departmentName}
                </option>
              ))}
            </select>
            <h1 className="blockB6 pb-2">Employment Type</h1>
            <select
              className={selectStyles}
              onChange={(e) => {
                e.target.value === "default"
                  ? updateType("default")
                  : updateType(types[e.target.value]?.employmentTypeName);
              }}
            >
              <option selected={state?.type === "default"} value="default">
                All Employment Types
              </option>
              {types?.map((item, i) => (
                <option
                  key={i}
                  value={i}
                  selected={state?.type === item?.employmentTypeName}
                >
                  {item?.employmentTypeName}
                </option>
              ))}
            </select>
            <h1 className="blockB6 pb-2">Location</h1>
            <select
              onChange={(e) => {
                e.target.value === "default"
                  ? updateLocation("default")
                  : updateLocation(locations?.[e.target.value]?.locationName);
              }}
              className={selectStyles}
            >
              <option selected={state?.location === "default"} value="default">
                All Locations
              </option>
              {locations?.map((item, i) => (
                <option
                  key={i}
                  value={i}
                  selected={state?.location === item?.locationName}
                >
                  {item?.locationName}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center">
            <GreyCross className="mr-2" />
            <p
              className="blockB6 text-[#828282] cursor-pointer"
              onClick={nukeFilters}
            >
              Clear all filters
            </p>
          </div>
        </div>
        {/* job listings */}
        <div className="col-start-5 2xl:col-start-4 col-end-11 pt-10 lg:pt-24">
          <h1 className="blockB5 text-[#828282] mb-10">Job Listings</h1>
          <div className="flex flex-col space-y-5">
            {filteredListings?.map((listing, i) => (
              <Link
                to={`${listing?.slug?.current}`}
                className="block border-b pb-5 border-gray-200 text-blue-dark "
              >
                <h1 className="blockB3 font-medium text-orange">
                  {listing?.title}
                </h1>
                <h2 className="blockB4">
                  {listing?.locationRef?.map((ref, i) => (
                    <span>
                      <span>{i > 0 && " or "}</span>
                      {ref?.locationName}
                    </span>
                  ))}
                </h2>
              </Link>
            ))}
          </div>
          <div>
            {filteredListings?.length === 0 && (
              <h1 className="blockB4 font-medium max-w-[485px]">
                Whilst there are no jobs matching this criteria, we are always
                interested to hear from you if you have a passion for the
                building services. Please get in touch with us at{" "}
                <a
                  className="text-orange underline underline-offset-4"
                  href="mailto:careers@cosgroves.com"
                >
                  careers@cosgroves.com
                </a>
              </h1>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Opportunities;

export const pageQuery = graphql`
  query opportunitiesQuery {
    page: sanityOpportunities {
      seo {
        ...SEO
      }
      heading
      intro
      cta
      images {
        ...ImageWithPreview
      }
    }
  }
`;
